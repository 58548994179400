@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap');

/* || RESET */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* BASIC STYLES */

body, html{
    font-family: 'Open Sans', sans-serif;
    position: relative;
    scroll-behavior: smooth;
}

body{
    background-color: #f5f5f5;
    color: #000;
    width: 100%;
}

h1, h2, h3, h4, h5, h6, p, a, ul, li, img, i{
    transition: all .3s ease-in-out;
}

h1, h2, h3, h4, h5, h6{
    font-weight: 700;
}

a{
    text-decoration: none;
    color: #000;
}

.register-btn a, .upcoming-events a, .social-links{
    color: #fff;
}

a:hover, .social-links i:hover{
    text-decoration: none;
    color: orangered;
}

ul{
    list-style: none;
}

img{
    max-width: 100%;
}


/* ||HEADER */
#main-header{
    background-color: #fff;
    /* min-height: 100vh; */
    overflow: hidden;
}

.rows{
    width: 100%;
    min-height: 18vh;
}

.top-row-one{
    background-color: rgb(107,0,62);
    color: #fff;
    padding: 10px 100px;
    text-transform: uppercase;

    display: flex;
    justify-content: space-between;
    align-items: center;

}

/* social-links SECTION */
.social-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.social-links i{
    font-size: 20px;
    color: #fff;
}

/* UPCOMING EVENT SECTION */
.upcoming-events{
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px;
}

#countdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
}

#countdown span{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* top-row-two */
.top-row-two{
    background-color: #eee;
    padding: 20px 100px;
    text-transform: uppercase;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    display: flex;
    justify-content: space-between;
    align-items: center;
}



/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%
  }


/* logo */
.logo{
    width: 50px;
    height: 50px;
}

.logo a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.logo span{
    font-size: 20px;
    font-weight: 700;
    color: #000;
    float: right;
}

/* nav */
.nav-links ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.nav-links ul li{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.nav-links ul li a{
    color: #000;
}

.nav-links ul li a:hover{
    color: orangered;
}

/* donate-btn */
.donate-btn{
    background-color: rgb(107,0,62);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.donate-btn a{
    color: #fff;
}

.donate-btn:hover{
    background-color: rgba(107, 0, 62, 0.8);
}

/* HERO SECTION */
.hero-section{
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) ,url(../../client/src/assets/hero-1.JPG);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 82vh;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.hero-text{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.hero-section h1{
    font-size: 50px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
}

.hero-section p{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0 40px 0;
    line-height: 1.7;
}

.hero-text a{
    background-color: rgb(107,0,62);
    padding: 15px 25px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}



/* ABOUT US SECTION */
.aboutDetails{
display: grid;
grid-template-columns: repeat(3,1fr);
align-items: center;
justify-content: center;
grid-gap: 40px;
text-align: center;
}


.about-us{
    padding: 100px 100px;
    background-color: #fff;
    color: #000;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    text-align: center;
}

.about-us-left, .about-us-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.left, .right{
    background-color: #eee;
    padding: 20px;
    border-radius: 5px;
    width: 250px;
    height: 200px;	
    text-transform: uppercase;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.left i, .right i{
    font-size: 40px;
    color: rgb(107,0,62);
}

.left img, .right img{
    width: 150px;
}

.about-us-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.about-us-center h2{
    font-size: 30px;
    font-weight: 400;
}

.about-us-center .blockquote{
    font-weight: 500;
    line-height: 1.7;    
}

.about-us-center h1{
    font-size: 60px;
    font-weight: 400;
    color: rgb(107,0,62);
}



/* upcoming event section */
.upcoming-events-section{
    width: 100%;
    padding: 70px 100px;
    padding-left: 200px;
    margin-bottom: 70px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.upcoming-events-section h1{
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.upcoming-events-section p{
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    margin: 20px 0 40px 0;
}