.item{
    display:'flex';
    min-width:30rem;
    min-height:15rem;
    padding:5px;
}
.item img{
   width:100%;
   height:100%;
 border-radius: 3rem;
 pointer-events:none;
}
.innerCarousel{
    display:flex;
    margin:15px;
    overflow:hidden;
}
.carousel{
    cursor:grab;
    overflow:hidden;
}