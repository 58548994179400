/* || RESET */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* || VARIABLES */


/* || GENERAL STYLES */
html, body {
  overflow-x: hidden;
  margin: 0 auto;
  margin-top: 7px;
  position: relative;
  background: var(--PRIMARY-BGCOLOR);
  font-family: var(--FF);
  width: 100%;
  font-size: var(--FS);
}

img{
  width: 100%;
  height: auto;
}

header{
  position: relative;
}

/* .........SHOWCASE HEADER SECTION.......... */
.bg-showcase{
  margin: 0 auto;
  width: 95%;
  height: 50vh;
  background-color: var(--BGCOLOR);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)) ,url(img/kyusda2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-showcase-announcement1{
  margin: 0 auto;
  width: 95%;
  height: 50vh;
  background-color: var(--BGCOLOR);
  background-image: url(img/announcement.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.bg-showcase-announcement2{
  margin: 0 auto;
  width: 95%;
  height: 50vh;
  background-color: var(--BGCOLOR);
  background-image: url(img/annoucement2.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}


  /* Styles for the slideshow container */
  /* Set maximum width, position, and center the container */
  .slideshow-container {
    height: 50vh;
    overflow: hidden;
    width: 100%;
    position: relative;
    margin: auto;
  }
  
  /* Hide the images by default */
  .mySlides {
    display: none;
  }
  
  /* Styles for the next and previous buttons */
  .prev, .next {
    background-color: rgba(0,0,0,0.8);
    cursor: pointer;
    position: relative;
    width: 10px;
    height: 10px;
    padding: 16px;
    color: aqua;
    font-weight: 200;
    font-size: 18px;
    transition: 0.6s ease;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  /* Position the "next button" to the right and hide it */
  .next {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

    /* Position the "prev button" to the left and hide it */
  .prev{
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: 40px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  
  /* On hover, add a light black background color */
  .prev:hover, .next:hover {
    background-color: #333;
  }

  /* show the control buttons when you hover */
  .slideshow-container:hover .prev,
  .slideshow-container:hover .next {
    display: flex;
}

.slideshow-container .slideshow-text{
    opacity: 1;
    transition: opacity 0.3s; /* Add a transition for smooth fade-in/out */
   position: absolute;
   top: 50%;
   left: 35%;
   transform: translate(-50%, -35%);
  }

  .slideshow-container .slideshow-text h2{
    color: #fff;
    text-shadow: 3px 4px 0 rgba(0,0,0,0.2);
    font-weight: 300;
    font-size: 4rem;
    letter-spacing: 1px;
    word-spacing: 4px;
    text-transform: uppercase;
  }

  .slideshow-container .slideshow-text .slogan{
    color: #fff;
    font-size: 1.5rem;
    padding-left: 20px;
   }
  
  /* Styles for number text (1/3, etc.) */
  .numbertext {
    color: #f2f2f2;
    font-size: 1.2rem;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    left: 40px;
  }

  /* Styles for the dots/bullets/indicators */

  .dots{
    position: absolute;
    bottom: 20%;
    right: 5%;
    transform: translate(-5%, -20%);
  }

  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: aqua;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  /* Styles for the active dot and hover effect */
  .active, .dot:hover {
    background-color: #fff;
  }
  
  /* Fading animation */
  /* Define a keyframe animation named 'fade' */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  /* Keyframes for the 'fade' animation */
  @keyframes fade {
    from {
      opacity: .4;
    }
    to {
      opacity: 1;
    }
  }


  /* ...........NAVIGATION SECTION............. */
  
  body.fixed-nav .site-wrap {
    transform: scale(1);
  }
  
  nav {
    background: var(--BGCOLOR);
    top: 0;
    width: 100%;
    transition:all 0.5s;
    position: relative;
    z-index: 1;
    box-shadow: var(--BOX-SHADOWS);
  }
  
  body.fixed-nav nav {
    position: fixed;
    box-shadow: var(--BOX-SHADOWS);
  }
  
  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  
  nav li {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  li.logo {
    max-width: 0;
    overflow: hidden;
    background: white;
    transition: all 0.5s;
  }

  .fixed-nav li.logo {
    max-width: 60px;
  }
  
  nav a {
    text-decoration: none;
    padding: 10px;
    display: inline-block;
    color: #fff;
    transition: all 0.5s;
    font-weight: 500;
    font-size: 1.1rem;
  }

  nav a:hover{
    transform: scale(1.1);
    color: aqua;
  }

   nav a:focus, nav a:active{
    transform: scale(1.1);
    color: aqua;
  }

  .active{
    background: #fff;
    color: #053B50;
    font-weight: 700;
    font-size: x-large;
    text-transform: lowercase;
    transition: all 1s;
  }
  
  .active:hover{
    transform: scale(0.9);
    color: #053B50;
  }

  .active:visited, .active:active, .active:focus{
    background-color: aqua;
    color: #053B50;
  }

  .nav-icons{
    color: #fff;
    padding: 5px;
  }


/* ..............ABOUT US SECTION.............. */
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  .site-wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 50px;
    max-width: 1200px;
    margin: 70px auto;
    background: #eee;
    padding: 40px;
    text-align: left;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
    transform: scale(0.98);
    transition: transform 0.5s;
  }

  .about-us-img img{
    width: 100%;
    width: 200px;
    height: 200px; 
    border-radius: 50%;
    margin: 150px 40px 0 40px;
  }

  .icons{
    position: absolute;
    bottom: 20%;
    left: 10%;
    transform: translate(-20%, -10%);
  }

  .icons a{
    padding: 8px;
  }
  

  .about-us-description h2{
    margin-bottom: 30px;
    text-align: center;
    font-size: 2.5rem;
    position: relative;
  }

  .about-us-description h2::after{
    content: '';
    border-bottom: 4px solid rgba(5, 59, 80, 0.7);
    display: block;
    width: 30%;
    position: absolute;
    top: 40px;
    bottom: 0;
    left: 35%;
  }

  .about-text-title{
    margin: 10px 0 5px 0;
    color: var(--BGCOLOR);
    font-weight: 900;
    font-size: 1.2rem;
    text-align: center;
  }

  /* ....................Featured content section..................... */
.featured-content{
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  border-bottom: 5px solid var(--BGCOLOR);
}

.featured-content-text h2{
  padding: 20px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.featured-content-img-holder{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
}

.img-holder{
  width: 100px;
  height: auto;
  margin: 10px;
}

.img-holder figcaption{
  font-size: 12px;
}



  /* ................FOOTER SECTION.............. */
.footer{
  display: block;
  background-color: #255760;
  width: 100%;
  height: 300px;
  margin-top: 70px;
}

.footer-title h2{
  color: #fff;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 10px;
}

.footer-title hr{
width: 80%;
margin: 0 auto;
margin-bottom: 20px;
}

.footer-content-left{
  color: #fff;
  font-size: smaller;
  font-weight: 500;
  float: left;
  margin-left: 25%;
  margin-top: 20px;
}

.footer-content-right{
  color: #fff;
  font-size: smaller;
  font-weight: 500;
  float: right;
  margin-right: 30%;
  margin-top: 20px;
}

.footer-content-right p, .footer-content-left p{
  padding: 10px;
}

.footer-content-right p i, .footer-content-left p i{
  padding-right: 10px;
}

.copyright{
  position: absolute;
  bottom: 0;
  text-align: center;
  background-color: #071112;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: smaller;
}