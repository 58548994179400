.breadcrumbs{
    background: linear-gradient(rgba(150, 60, 221, 0.1), rgba(17, 17, 69, 0.8)), url('../../assets/area.jpg');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.breadcrumb-container
{
	height: 34vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1.5rem;
}
.heading{
	margin: 20px auto;
}
.breadcrumb-container .hero-heading
{
	font-size: 3rem;
}
.team-leaders
{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	gap: 10px;
	justify-content: center;
	align-items: center;
	margin: auto;
 /* margin: 2rem 0;
	display: flex;
	flex-direction: column;
	gap: 4rem; */
}
.team-leader .leader-img
{
	border-radius: 500px 500px 500px 500px;
    height: 300px;
    width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin:10px auto;
}
.team-leader .leader-content
{
	padding: 1rem;
}
.team-leader .leader-designation
{
	font-size: 0.8rem;
	font-weight: 500;
	text-transform: uppercase;
}
.team-leader .leader-name
{
	font-size: 1.6rem;
	font-weight: 600;
	margin: 0.6rem 0;
}
.team-members
{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	justify-content: center;
	align-items: center;
	margin: 30px auto;
	gap: 10px;
}
.team-member .member-img
{
	width: 300px;
	border-radius: 6px;
}
.team-member .member-content
{
	padding: 1rem;
}
.team-member .member-designation
{
	font-size: 0.8rem;
	font-weight: 500;
	text-transform: uppercase;
}
.team-member .member-name
{
	font-size: 1.6rem;
	font-weight: 600;
	margin: 0.6rem 0;
}
.future{
	height: 25vh;
}
@media screen and (min-width:789px){
    .breadcrumb-container 
	{
		height: 50vh;
	}
    .future .container
	{
		text-align: center;
	}

	.future .para-line
	{
		max-width: 1050px;
		margin: 0 auto;
	}

    .future-protection-imgs
	{
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;
	}
    .key-numbers .container .para-line
	{
		max-width: 800px;
	}
    .key-num-row
	{
		justify-content: center;
		align-items: flex-start;
    }
    .key-num-2-col
	{
		margin-top: 4rem;
	}
    .our-team .container
	{
		text-align: center;
	}
    .team-members
	{
		flex-direction: row;
	}
    .team-member .member-content
	{
		text-align: left;
	}
}